<template>
  <v-sheet :class="!styleTheme ? 'ma-3' : null" :color="styleTheme ? 'grey' : 'transparent'">
    <v-autocomplete
      v-model="customer"
      label="Müşteri"
      placeholder="Müşteri Seçin"
      persistent-placeholder
      :items="customerItems"
      :loading="customerLoading"
      :filled="styleTheme"
      :background-color="styleTheme ? 'grey' : 'transparent'"
      color="white"
      hide-details
      :rounded="styleTheme"
      item-text="name1"
      item-value="customer"
      @change="customerChange"     
      :search-input.sync="searchText"
      clearable
    >
      <template v-if="!$vuetify.breakpoint.mdAndDown" v-slot:prepend>
        <v-icon>{{ icons.mdiDomain }}</v-icon>
      </template>
      <template v-slot:item="{ item }" class="ahmet">
        <v-list-item-content>
          <v-list-item-title class="white--text font-weight-bold" style="font-size: 15px;">         
              {{ item.name1 }}           
              <v-list-item-title class="black--text font-weight-bold" style="font-size: 12px;" >     

               Müşteri No: {{ item.customer }}  
            </v-list-item-title>        
          </v-list-item-title>
          <!-- <v-list-item-subtitle>
            {{ item.address || 'Adres Bilgisi Yok' }}
          </v-list-item-subtitle> -->
        </v-list-item-content>
           <v-list-item-action>
            <v-chip :color="item.islocked == 0 ? 'success' : 'error'" label>
              {{ item.islocked==0 ?"Aktif":"Pasif" }}
            </v-chip>
        </v-list-item-action>
      </template>
    </v-autocomplete>
  </v-sheet>
</template>

<script>
import { mdiDomain } from '@mdi/js'

import store from '@/store'
import { useRouter } from '@core/utils'
import { onMounted, ref,watch } from '@vue/composition-api'
import Vue from 'vue'
import debounce from 'lodash/debounce'
export default {
  props: {
    styleTheme: {
      type: Boolean,
      default: false,
    },
  }, 
  setup() {
    const { router } = useRouter()

    const customer = ref(null)
    const customerItems = ref([])
    const customerLoading = ref(false)
    const debounceTimeout= null
   
    const searchText= ref('')
    onMounted(() => {
      debugger
      customerLoading.value = true
      fetchAllCustomers()
      /*  const data = {
        method: 'getCustomersBySalDept',
        type: 'select',
      }
      store
        .dispatch('postMethod', data)
        .then(response => {
          customerItems.value = response.detail
          customer.value = store.state.plasiyerCustomer
        })
        .catch(e => {
          // loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
        .finally(() => {
          customerLoading.value = false
        })  */
    })

    const fetchAllCustomers = () => {
      debugger
      const data = {
        method: 'getCustomersBySalDept',
        type: 'select',
      }
      store
        .dispatch('postMethod', data)
        .then(response => {
          debugger
          customerItems.value = response.detail
          customer.value = store.state.plasiyerCustomer
        })
        .catch(e => {
          // loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
        .finally(() => {
          customerLoading.value = false
        })
    }
    const fetchCustomers = debounce(async (query) => {
      debugger
      if (query!== null && query.length > 2 && query.length < 10) {
        customerLoading.value = true
        const data = {
        method: 'getCustomersBySalDept',
        q: query,
      }
      store
        .dispatch('postMethod', data)
        .then(response => {
          customerItems.value = response.detail
          customer.value = store.state.plasiyerCustomer
        })
        .catch(e => {
          // loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
        .finally(() => {
          customerLoading.value = false
        })
      } else {
        //customerItems.value = []
      }
    }, 300) // Debounce delay in milliseconds

    // Watch for changes in searchText and trigger fetchCustomers
    watch(searchText, (newValue) => {
      debugger
      if (newValue === null) {
        fetchAllCustomers()     
        store.commit('PLASIYER_CUSTOMER', null)   
        
      }
      else{
        fetchCustomers(newValue)
      }      
    })

    const customerChange = () => {
      debugger
      const data = {
        method: 'changeActiveCustomer',
        customer: customer.value,
      }
      store
        .dispatch('postMethod', data)
        .then(response => {
          if (response.error == 1) {
            store.commit('SECOND_STATE', false)
            Vue.swal({
              title: 'Hata',
              text: response.msg,
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          } else if (response.error == 2) {
            store.commit('PLASIYER_CUSTOMER', customer.value)
            store.commit('SECOND_STATE', true)
            store.commit('SECOND_MSG', response.msg)
            Vue.swal({
              title: 'Hata',
              html: store.state.secondMsg,
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            console.log(customer.value)
            router.push({ name: 'payment' })
          } else {
            store.commit('SECOND_STATE', false)
            store.commit('PLASIYER_CUSTOMER', customer.value)
          }
        })
        .finally(() => {
          customerLoading.value = false
        })
    }
    return {
      customerItems,
      customerLoading,
      customer,
      customerChange,
      debounceTimeout,
      searchText,
      icons: { mdiDomain },
    }
  }, 
  methods: {
    onSearchInput(value) {
      this.searchText = value;
      console.log('Search input text:', value);
    }
  
  }

}
</script>
<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
  height: auto !important;
}
</style>
