<template>
  <div class="vertical-shopping-cart-container pt-10 pt-md-0">
    <div
      v-if="!$vuetify.breakpoint.mdAndUp"
      class="text-right pa-1 elevation-10"
      style="position: fixed; top: 0; left: 0; width: 100%; z-index: 10"
    >
      <v-btn x-small block color="error" @click="closeBasket()">
        <span class="text-caption text-capitalize">Sepeti Kapat</span>
        <v-icon right>
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-snackbars :objects.sync="snackbar" width="500" top left transition="slide-y-transition">
      <template v-slot:action="{ close }">
        <v-btn icon small @click="close()">
          <v-icon small>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbars>

    <v-dialog v-model="dialog" eager width="500" persistent>
      <v-btn color="error" block @click="paymentCancel">
        <v-icon left>
          {{ icons.mdiClose }}
        </v-icon>
        İşlemi İptal Et
      </v-btn>
      <div id="credit"></div>
    </v-dialog>

    <v-dialog v-model="mesafeliStatus" scrollable max-width="600px">
      <v-card>
        <v-card-title>Mesafeli Satış Sözleşmesi</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <div v-html="mesafeliSatisSozlesmesi"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="mesafeliStatus = false"> Kapat </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="onBilgilendirmeStatus" scrollable max-width="600px">
      <v-card>
        <v-card-title>Ön Bilgilendirme Sözleşmesi</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <div v-html="onBilgilendirmeSozlesmesi"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="onBilgilendirmeStatus = false"> Kapat </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <perfect-scrollbar id="container" ref="scrollbar" class="ps-nav-menu-items" :options="perfectScrollbarOptions">
      <v-stepper
        v-if="$store.state['app-order'].basketItemCount > 0"
        v-model="basketStep"
        class="basket-stepper"
        vertical
        elevation="0"
      >
        <v-stepper-step
          :complete="basketStep > 1"
          :editable="basketStep > 1 && !loading"
          step="1"
          :class="$vuetify.breakpoint.mdAndUp ? 'px-2' : 'pa-0'"
        >
          <v-list class="pa-0">
            <v-list-item>
              <v-list-item-avatar
                :size="$vuetify.breakpoint.mdAndUp ? 64 : 48"
                :color="basketStep == 1 ? 'secondary' : 'primary'"
              >
                <v-icon :size="$vuetify.breakpoint.mdAndUp ? 32 : 24" class="white--text">
                  {{ icons.mdiBasket }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-h6 text-md-h5 pb-1 font-weight-medium"> Sepet </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="basketStep == 1 && $vuetify.breakpoint.mdAndUp">
                <v-btn icon @click="closeBasket()">
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action v-else-if="basketStep != 1 && $vuetify.breakpoint.mdAndUp">
                <v-chip v-if="basketStep != 1" large color="">
                  <span class="text-body-2 px-3 black--text"> Sepet Toplam Tutarı</span>
                  <v-chip color="info">
                    {{ toplamFiyat | currency }}
                  </v-chip>
                </v-chip>
              </v-list-item-action>
              <v-list-item-action v-else-if="basketStep != 1 && !$vuetify.breakpoint.mdAndUp">
                <v-chip label class="text-caption">
                  {{ toplamFiyat | currency }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-stepper-step>

        <v-stepper-content step="1" :style="$vuetify.breakpoint.mdAndUp ? null : 'padding: 16px 45px 16px 8px;'">
          <v-card v-if="!sepetLoading" color="grey lighten-1" class="mb-6" rounded="lg">
            <v-card-text :class="!$vuetify.breakpoint.mdAndUp ? 'pa-0' : 'pt-0'">
              <v-list class="pa-0" color="grey" :dense="!$vuetify.breakpoint.mdAndUp">
                <div v-for="(line, index) in shoppingCartItems.detail" :key="`LIST-${index}`">
                  <v-hover v-slot="{ hover }">
                    <v-list-item
                      :three-line="!$vuetify.breakpoint.mdAndUp"
                      :two-line="$vuetify.breakpoint.mdAndUp"
                      :class="hover ? 'grey' : null"
                      class="px-2"
                    >
                      <v-list-item-avatar
                        v-if="$vuetify.breakpoint.mdAndUp"
                        :size="$vuetify.breakpoint.mdAndUp ? 75 : 50"
                      >
                        <v-avatar :size="$vuetify.breakpoint.mdAndUp ? 75 : 50" tile>
                          <v-img
                            :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                            :max-width="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                            :src="
                              line.resim
                                ? 'https://b2bapi.mesan.com.tr/' + line.resim
                                : require('@/assets/images/misc/not-image.png')
                            "
                          ></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-action>
                        <v-sheet :max-width="$vuetify.breakpoint.mdAndUp ? 140 : 100" color="transparent">
                          <CounterTo
                            :uid="line.uid"
                            :plant-id="line.selected_plant"
                            :miktar="line.qty"
                            :birim="line.birim"
                            :paym-cond="line.paymCond"
                            :birim-ritim="line.birimRitmi"
                            bg-color="counter"
                            button-color="black"
                            :light="true"
                            @refreshBasket="refreshBasket()"
                          />
                        </v-sheet>
                      </v-list-item-action>
                      <v-list-item-content :class="$vuetify.breakpoint.mdAndUp ? 'ml-6' : 'ml-0 align-self-center'">
                        <v-list-item-title
                          class="text-caption text--secondary text-md-body-2 pt-1"
                          :style="$vuetify.breakpoint.mdAndUp ? null : 'white-space: normal !important'"
                        >
                          <v-chip color="secondary" small>
                            {{ line.urun.urun_kodu }}
                          </v-chip>
                        </v-list-item-title>
                        <v-list-item-title
                          class="text-caption text--secondary text-md-body-2 pt-1"
                          :style="$vuetify.breakpoint.mdAndUp ? null : 'white-space: normal !important'"
                        >
                          {{ line.baslik }}
                        </v-list-item-title>

                        <v-list-item-subtitle class="mt-1 text--secondary font-weight-bold">
                          {{ (line.birimFiyat * line.qty) | currency }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action v-if="!$vuetify.breakpoint.mdAndUp || hover" class="ml-0">
                        <v-btn icon @click="deleteBasketItem(line.baslik, line.localCode, line.selected_plant)">
                          <v-icon>
                            {{ icons.mdiTrashCan }}
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-hover>
                  <v-divider></v-divider>
                </div>

                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right"> Satır Brüt Fiyatı </v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ toplamBrutFiyat | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right"> KDV </v-list-item-title>
                    <v-list-item-subtitle class="text-right font-weight-bold">
                      {{ satirToplamKDV | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right"> Toplam İndirim Tutarı </v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ toplamIndirimTutari | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right">İskonto Oranı </v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ toplamIndirimOrani | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right"> Toplam Fiyat </v-list-item-title>
                    <v-list-item-subtitle class="text-right success--text font-weight-bold">
                      {{ toplamFiyat | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions class="px-2 px-md-4 pt-6 pt-md-2">
              <v-btn
                :plain="!$vuetify.breakpoint.mdAndUp"
                :small="!$vuetify.breakpoint.mdAndUp"
                text
                outlined
                class="text-capitalize"
                @click="trashBasket()"
              >
                <v-icon left color="error">
                  {{ icons.mdiTrashCan }}
                </v-icon>
                <span> Sepeti Temizle</span>
              </v-btn>
              <v-spacer></v-spacer>

              <v-alert v-if="errorShow" type="error" class="ma-0" text outlined dense>
                <span class="text-caption">Kayıtlı adresiniz olmadığı için devam edemiyoruz</span>
              </v-alert>
              <v-btn v-if="$vuetify.breakpoint.mdAndUp" text class="text-capitalize" plain @click="closeBasket">
                Vazgeç
              </v-btn>
              <v-btn
                v-if="!errorShow"
                color="tertiary"
                class="text-capitalize white--text"
                x-large
                depressed
                :loading="loading"
                @click="basketStep2()"
              >
                Devam
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-sheet v-else color="grey" class="rounded-lg d-flex justify-center pa-6">
            <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
          </v-sheet>
        </v-stepper-content>

        <v-stepper-step
          :complete="basketStep > 2"
          :editable="basketStep > 2 && !loading"
          :class="$vuetify.breakpoint.mdAndUp ? 'px-2' : 'pa-0'"
          step="2"
        >
          <v-list class="pa-0">
            <v-list-item two-line>
              <v-list-item-avatar
                :size="$vuetify.breakpoint.mdAndUp ? 64 : 48"
                :color="basketStep == 2 ? 'secondary' : 'primary'"
              >
                <v-icon :size="$vuetify.breakpoint.mdAndUp ? 32 : 24" class="white--text">
                  {{ icons.mdiTruckFast }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h6 text-md-h5 pb-1 font-weight-medium">
                  Siparişi Tamamla
                </v-list-item-title>
                <div v-if="basketStep == 3" class="d-block d-md-flex justify-space-between">
                  <div class="flex-grow-1">
                    <v-list-item-subtitle class="mb-1 mb-md-0" style="text-shadow: none !important">
                      <span class="black--text">Teslimat Adresi : </span>
                      <span class="text-decoration-underline black--text">{{ address_title }}</span>
                    </v-list-item-subtitle>
                  </div>
                  <!--  <div class="flex-grow-1">
                    <v-list-item-subtitle class="" style="text-shadow: none !important">
                      <span class="black--text">Teslimat Tarihi : </span>
                      <span class="text-decoration-underline black--text">{{ deliveryDate }}</span>
                    </v-list-item-subtitle>
                  </div>-->
                </div>
              </v-list-item-content>
              <v-list-item-action v-if="basketStep > 2 && $vuetify.breakpoint.mdAndUp">
                <v-chip large color="">
                  <span class="text-body-2 px-3 black--text"> Teslimat Tutarı</span>
                  <v-chip color="info">
                    {{ cargoPrice | currency }}
                  </v-chip>
                </v-chip>
              </v-list-item-action>
              <v-list-item-action v-else-if="basketStep > 2 && !$vuetify.breakpoint.mdAndUp">
                <v-chip label class="text-caption">
                  {{ cargoPrice | currency }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-stepper-step>

        <v-stepper-content step="2" :style="$vuetify.breakpoint.mdAndUp ? null : 'padding: 16px 45px 16px 8px;'">
          <v-card color="grey lighten-1" class="mb-12">
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large left>
                    {{ icons.mdiMapMarker }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Teslimat Adresi</v-list-item-title>
                  <v-list-item-subtitle> {{ address_title }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-card-text>
              <template>
                <v-item-group v-model="addressSelect" mandatory>
                  <v-row>
                    <v-col v-for="item in addressItems" :key="`${item.id}-address`" cols="12" md="6">
                      <v-item v-slot="{ active, toggle }">
                        <v-card :color="active ? 'secondary' : ''" class="pa-3" dark height="150" @click="toggle">
                          <v-chip :color="active ? 'info' : 'grey'" small label>
                            {{ item.address_title }}
                          </v-chip>
                          <div class="pt-3 white--text font-weight-bold">{{ item.city_txt }} / {{ item.state }}</div>
                          <div
                            class="pt-1"
                            style="line-height: 1.25; font-size: 13px"
                            :class="active ? ' font-weight-medium' : null"
                          >
                            {{ item.address }}
                          </div>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-item-group>
              </template>
            </v-card-text>

            <v-divider class="my-3"></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="mt-5" x-large left>
                    {{ icons.mdiTruckDelivery }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span style="font-size: large">Teslimat Tipi </span>
                 <!--  <font size="2" face="arial" color="green">
                    Kargo teslimatlarında, 15:00'e kadar girilecek siparişlerde bugün kargoya teslim!
                   </font> -->

                  <v-autocomplete
                    v-model="deliveryList"
                    :items="deliveryItems"
                    item-text="kargo_adi"
                    item-value="kid"
                    label="Teslimat Tipini Seçiniz..."
                    persistent-hint
                    return-object
                    single-line
                    class="rounded-0 mt-3 custom-select"
                    search
                    clearable
                  >
                  </v-autocomplete>
                </v-list-item-content>
              </v-list-item>
            </v-list>
         

            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large left>
                    {{ icons.mdiText }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Teslimat Notu</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-card-text>
              <v-textarea
                v-model="buyerNote"
                auto-grow
                placeholder="Bir not girmek istiyorsanız buraya yazın"
                rows="1"
                row-height="15"
              />
            </v-card-text>

          

            <v-list>
              <v-list-item>                
                <v-list-item-content>
                  <div  
                  class="mt-3 mb-6 mr-2 rounded-pill"
                  style="text-align: center; background: linear-gradient(to right, #01c1f4, #0083e8)"
                >
                  <v-alert text dense dark class="rounded-pill">
                    <div class="white--text">
                      <strong>{{ payCondition }}</strong>
                    </div>
                  </v-alert>
                </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>

           

           <!--  <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="mt-5" x-large left>
                    {{ icons.mdiBriefcase }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span style="font-size: large">İş Alanı</span>
                  <v-autocomplete
                    v-model="isAlani"
                    :items="isAlaniItems"
                    :item-text="customItemText"
                    item-value="value"
                    label="İş Alanı"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-autocomplete>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="mt-5" x-large left>
                    {{ icons.mdiStateMachine }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span style="font-size: large">Statü</span>
                  <v-autocomplete
                    v-model="statu"
                    :items="statuItems"
                    :item-text="customItemText1"
                    item-value="STATUSDETAIL"
                    label="Statü"
                    persistent-hint
                    return-object
                    single-line
                    class="rounded-0 mt-3 custom-select"
                    search
                    clearable
                  >
                  </v-autocomplete>
                </v-list-item-content>
              </v-list-item>
            </v-list> -->
          </v-card>

          <div class="mb-6 d-flex align-center">
            <v-btn
              :plain="!$vuetify.breakpoint.mdAndUp"
              :small="!$vuetify.breakpoint.mdAndUp"
              text
              outlined
              class="text-capitalize black--text"
              @click="backBasket(1)"
            >
              <v-icon left>
                {{ icons.mdiChevronUp }}
              </v-icon>
              Sepet
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="$vuetify.breakpoint.mdAndUp" text class="text-capitalize" plain @click="closeBasket">
              Vazgeç
            </v-btn>

            <v-btn
              color="tertiary"
              class="text-capitalize white--text"
              x-large
              depressed
              :loading="loading"
              @click="basketStep3()"
            >
              Siparişi Tamamla
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper>
      <v-card v-else flat tile height="100%">
        <v-list class="py-6 px-2">
          <v-list-item>
            <v-list-item-avatar
              :size="$vuetify.breakpoint.mdAndUp ? 64 : 48"
              :color="basketStep == 1 ? 'primary' : 'grey'"
            >
              <v-icon :size="$vuetify.breakpoint.mdAndUp ? 32 : 24" class="white--text">
                {{ icons.mdiBasketOff }}
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-h6 text-md-h5 pb-1 font-weight-medium"> Sepet </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="basketStep == 1 && $vuetify.breakpoint.mdAndUp">
              <v-btn icon @click="closeBasket()">
                <v-icon>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div class="d-flex align-center justify-center" style="height: calc(100% - 300px)">
          <div class="text-center">
            <v-icon color="primary" size="100">
              {{ icons.mdiBasketOff }}
            </v-icon>
            <div class="text-body-2 pt-1 pb-6">Sepetiniz Boş Lütfen Ürün Ekleyin</div>
            <v-btn depressed outlined small @click="closeBasket()"> Kapat </v-btn>
          </div>
        </div>
      </v-card>
    </perfect-scrollbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { getVuetify, useRouter } from '@core/utils'

import {
  mdiBank,
  mdiBasket,
  mdiBasketOff,
  mdiBriefcase,
  mdiCalendar,
  mdiChevronDown,
  mdiChevronUp,
  mdiCircle,
  mdiClose,
  mdiContactlessPayment,
  mdiCreditCard,
  mdiCreditCardPlus,
  mdiMapMarker,
  mdiRefresh,
  mdiStateMachine,
  mdiText,
  mdiTrashCan,
  mdiTruckFast,
  mdiTruckDelivery,
} from '@mdi/js'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'

// 3rd Party
import CardForm from '@/components/CardForm.vue'
import CounterTo from '@/components/CounterTo.vue'
import store from '@/store'
import orderStoreModule from '@/views/order/orderStoreModule'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { setTimeout } from 'timers'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    CounterTo,
    CardForm,

    // 3rd Party
    PerfectScrollbar,
    VSnackbars,
  },
  setup() {
    const birinciKart = ref(null)
    const ikinciKart = ref(0)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const buttonText = ref('Alışverişi Tamamla')
    const kredikartino = ref('')
    const kredikartiadsoyad = ref('')
    const kredikartiinstallment = ref([])
    const kredikartiay = ref('')
    const kredikartiyil = ref('')
    const kredikarticvv = ref('')
    const { router } = useRouter()
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-order'
    const shoppingCartItems = ref([])
    const addressItems = ref([])
    const mesafeliStatus = ref(false)
    const onBilgilendirmeStatus = ref(false)
    const errorShow = ref(false)
    const bankalar = ref([])
    const cargoPrice = ref(0)
    const toplamOdenecekTutar = ref(0)
    const toplamOdenecekTutarHavale = ref(0)
    const formValidate = ref([])
    const bankItem = ref(0)
    const buyerNote = ref(null)
    const valid = ref(true)
    const sepetLoading = ref(true)
    const cardNumberValidate = ref(false)
    const cardNameValidate = ref(false)
    const cardMountValidate = ref(false)
    const cardYearValidate = ref(false)
    const cardCvvValidate = ref(false)
    const kkAdSoyad = ref(null)
    const kkAy = ref(null)
    const kkYil = ref(null)
    const kkCvv = ref(null)
    const kkNo = ref(null)
    const selectedInstallment = ref(null)
    const bankaKodu = ref(null)
    const cardFamily = ref(null)
    const cardBankName = ref(null)
    const number = ref(2)
    const musteri = ref(1)
    const fabrika = ref(1)
    const tarih = ref(1)
    const isAlani = ref({})
    const statu = ref('')

    const isMulti = ref(false)
    const basketStep = ref(1)
    const minDate = ref(null)
    const maxDate = ref(null)
    const tab = ref(0)
    const deliveryDate = ref(null)
    const loading = ref(false)
    const dialog = ref(false)
    const menuDeliveryDate = ref(false)
    const checkbox1 = ref(userData.role === 'PLSYR')
    const checkbox2 = ref(userData.role === 'PLSYR')
    const taksitlendirme = ref({})
    const taksitSecim = ref(null)
    const taksitTutar = ref(0)
    const taksitSayisi = ref(0)
    const snackbar = ref([])
    const form = ref(null)
    const whileStop = ref(false)
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]
    const hasPromotion = ref(true)
    const hasMalFazlasi = ref(true)
    const scrollbar = ref()
    const payCondition = ref('')
    const deliveryList = ref('')
    onMounted(() => {
      const $vuetify = getVuetify()
      if ($vuetify.breakpoint.smAndDown) scrollbar.value.destroy()
debugger
// musteri sepete ulasamıyordu userData.role === 'PLSYR' eklendi.
      if (userData.role === 'PLSYR' && store.state.secondState == true) {
        Vue.swal({
          title: 'Hata',
          html: 'Sadece ödeme yapılabilir',
          icon: 'error',
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: false,
        })
        store.commit('app/TOGGLE_BASKET_DRAWER', false)
      }

      if (userData.role === 'CUST' && userData.islocked === 1) {
        debugger
        Vue.swal({
          title: 'Hata',
          text: 'Sadece ödeme yapılabilir',
          width: '350px',
          icon: 'error',
          background: '#FF4C51',
          showCloseButton: false,
          showConfirmButton: true,
          confirmButtonColor: '#cc3d41',
        })
        store.commit('app/TOGGLE_BASKET_DRAWER', false)
        router.push({ name: 'payment' })
      }
  
    })

    onMounted(() => {
     
      deliveryDate.value = store.state['app-order'].deliveryDate
      minDate.value = store.state['app-order'].deliveryMinDate
      maxDate.value =
        userData.role === 'PLSYR'
          ? store.state['app-order'].deliveryMaxDatePLSYR
          : store.state['app-order'].deliveryMaxDate
      
    })
    const dateChange = () => {
      store.commit('app-order/DELIVERY_DATE', deliveryDate.value)
    }
    const addressSelect = ref([])
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, orderStoreModule)
    }
    const counts = computed(() => Array.from({ length: 100 }, (_, i) => i + 1))

    onMounted(() => {
      store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
          shoppingCartItems.value = response
          sepetLoading.value = false
        } else {
          console.table('hatalı =>', response)
        }
      })
      store
        .dispatch('app-order/fetchAddressList', {
          method: 'getAddressListByUid',
          uid: '1039',
        })
        .then(response => {
          if (response.error === 0) {
            addressItems.value = response.response
            errorShow.value = false

            //  console.table('başarılı =>', response)
          } else {
            errorShow.value = true
            console.table('hatalı =>', response)
          }
        })
      store
        .dispatch('app-order/fetchBankaList', {
          method: 'getBankaTransferList',
        })
        .then(response => {
          if (!response.error) {
            bankalar.value = response.detail

            console.table('başarılı =>', response)
          } else {
            console.table('hatalı =>', response)
          }
        })
    })

    const refreshBasket = () => {
      
      store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
          shoppingCartItems.value = response
        } else {
          //   console.table('hatalı =>', response)
        }
      })
    }

    const isRamazanEvent = (uid, qty, paymCond, plant, isRamazan) => {
      store
        .dispatch('app-order/fetchAddToBasket', {
          method: 'addToBasket',
          uid,
          qty,
          paymCond,
          plant,
          isRamazan,
        })
        .then(response => {
          if (response.error) {
          } else {
          }
        })
    }

    watch(
      () => store.getters['app-order/doneDelivery'],
      () => {
        refreshBasket()
      },
    )

    const closeBasket = () => {
      store.commit('app/TOGGLE_BASKET_DRAWER', false)
    }

    const deleteBasketItem = (baslik, id) => {
      store.commit('app/TOGGLE_BASKET_DRAWER_TEMPORARY', false)
      Vue.swal({
        title: 'Emin misin?',
        text: 'Silme işlemini onaylayın',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order/fetchDeleteBasketItem', { id }).then(response => {
            if (response.error === 0) {
              shoppingCartItems.value = []
              snackbar.value.push({
                message: `${baslik} Ürünü Silindi`,
                color: 'error',
                timeout: 3000,
              })
              store.dispatch('app-order/fetchActiveBasketList').then(response1 => {
                if (!response.error) {
                  shoppingCartItems.value = response1
                  if (typeof response1.detail === 'undefined') {
                    store.commit('app/TOGGLE_BASKET_DRAWER', false)
                  }

                  //     store.commit('app/TOGGLE_BASKET_DRAWER_TEMPORARY', true)
                } else {
                  store.commit('app/TOGGLE_BASKET_DRAWER', false)
                }
              })
            } else {
              Vue.swal({
                title: 'Hata',
                html: response.msg,
                icon: 'error',
                background: '#FF4C51',
                timerProgressBar: true,
                showConfirmButton: false,
              })
            }
          })
        }
      })
    }

    const trashBasket = () => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Sepeti Temizlemek İstiyormusunuz',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order/fetchTrashBasket').then(() => {
          debugger
            store.dispatch('app-order/fetchActiveBasketList').then(response => {
              debugger
              store.commit('app/TOGGLE_BASKET_DRAWER', false)
              Vue.swal({
                title: 'Sepet Temizlendi',
                text: 'İşlem başarılı',
                icon: 'success',
                timer: 1500,
                background: '#56CA00',
                timerProgressBar: true,
                showConfirmButton: false,
              })
            })
          })
        }
      })
    }

    const sleep = ms =>
      new Promise(resolve => {
        setTimeout(resolve, ms)
      })

    const backBasket = val => {
      const container = document.querySelector('#container')
      container.scrollTop = 0
      basketStep.value = val
    }
    const responseStep2 = ref({})
    const onBilgilendirmeSozlesmesi = ref(null)
    const mesafeliSatisSozlesmesi = ref(null)
    const isAlaniItems = ref([])
    const statuItems = ref([])

    const basketStep2 = () => {

      fetchDeliveryListItems()
      loading.value = true
      const payload = {
        hasPromotion: hasPromotion.value,
        hasMalFazlasi: hasMalFazlasi.value,
      }
      store
        .dispatch('app-order/fetchGetOrderStep1Data', payload)
        .then(response => {
          if (response.error === 0) {            
            const container = document.querySelector('#container')
            container.scrollTop = 0
            basketStep.value = 2
            const obj = response.detail.busareaList
            const array = Object.entries(obj).map(([key, value]) => ({ key, value }))
            isAlaniItems.value = array
            isAlani.value = array[0]
            statuItems.value = response.detail.statuList
            payCondition.value=response.detail.paymcondtxt
         
          } else {
            Vue.swal({
              title: 'Hata',
              html: response.msg,
              icon: 'error',

              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const tabKKDisabled = ref(false)
    const tabHavaleDisabled = ref(false)
    const tabCHDisabled = ref(false)
    const tabCHnone = ref(false)
    if (userData.role === 'PLSYR') {
      tabCHnone.value = true
    }
    const basketStep3 = () => {
      loading.value = true
      debugger
      if (!deliveryList.value) {
        Vue.swal({
          title: 'Hata',
          html: 'Lütfen Teslimat Tipini Seçiniz!',
          icon: 'error',
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: false,
        })
        loading.value = false
      } else { 

        const payload = {
          deliveryAddr: addressItems.value?.[addressSelect.value]?.id,
          invoiceAddr: addressItems.value?.[addressSelect.value]?.id,
          cargo: deliveryList.value.kid,//11,
          deliveryDate: deliveryDate.value,
          buyer_note: buyerNote.value,
          busarea: isAlani.value.key,
          salDocStatus: statu.value.STATUSDETAIL,
        }
        store.dispatch('app-order/fetchMakeOrderStep1', payload).then(result => {
          if (result.error === 0) {
            store.dispatch('app-order/fetchGetOrderStep2Data').then(response => {
              responseStep2.value = response
              const payloadPay = {
                method: 'makeOrderStep2',
                paymentType: 6,
                banka: bankalar.value[bankItem.value]?.id,
              }

              store.dispatch('app-order/fetchMakeOrderStep2', payloadPay).then(response => {
                if (response.error == 1) {
                  Vue.swal({
                    title: 'Hata',
                    html: response.msg,
                    icon: 'error',
                    background: '#FF4C51',
                    timerProgressBar: true,
                    showConfirmButton: false,
                  })
                  loading.value = false
                } else {
                  store.dispatch('app-order/fetchGetOrderStep3Data').then(response => {
                    //mesafeliSatisSozlesmesi.value = response.mesafeliSatisSozlesmesi
                    //onBilgilendirmeSozlesmesi.value = response.onBilgilendirmeSozlesmesi
                    const container = document.querySelector('#container')

                    container.scrollTop = 0
                    cargoPrice.value = responseStep2.value.detail?.ordering?.totalCargoAmount || 0
                    basketStep.value = 3
                    toplamOdenecekTutar.value = responseStep2.value.detail?.ordering?.totalAmount || 0
                    toplamOdenecekTutarHavale.value = responseStep2.value.detail?.ordering?.totalAmount || 0
                    loading.value = false

                    //toplamOdenecekTutar.value = responseStep2.value.detail?.ordering?.totalAmount || 0
                    //toplamOdenecekTutarHavale.value = responseStep2.value.detail?.ordering?.totalAmount || 0

                    store.dispatch('app-order/fetchMakeOrderStep3', payload).then(async response => {
                      if (response.error === 0) {
                        store.commit('app/TOGGLE_BASKET_DRAWER', false)
                        router.push({ name: 'order-completed' })
                        paymentErrorMsg.value = response.msg
                        paymentError.value = true
                      } else {
                        Vue.swal({
                          title: 'Hata',
                          html: response.msg,
                          icon: 'error',
                          background: '#FF4C51',
                          timerProgressBar: true,
                          showConfirmButton: false,
                        })
                      }
                    })
                  })
                }
              })
            })
          } else {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              html: result.msg,
              icon: 'error',

              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }
        })
      }
    }
    const orderStatus = ref()
    const paymentErrorMsg = ref(null)
    const paymentError = ref(false)
    let iframe

    const krediKartTemizle = ref(0)
    window.addEventListener('message', e => {
      if (e.data.success) {
        dialog.value = false
        iframe.remove()

        if (e.data.ismulti) {
          if (e.data.iscomplete) {
            store.commit('app/TOGGLE_BASKET_DRAWER', false)
            router.push({ name: 'order-completed' })
          } else {
            isMulti.value = true
            ikinciKart.value = e.data.multicardremainamount
            buttonText.value = 'İkinci Kart İle Devam'
            krediKartTemizle.value += 1
          }
        } else {
          store.commit('app/TOGGLE_BASKET_DRAWER', false)
          router.push({ name: 'order-completed' })
        }
      }
    })
    const basketStepCompleted = async () => {
      paymentErrorMsg.value = ''
      paymentError.value = false
      if (tab.value === 0 || tab.value === 1) {
        if (!form.value.validate()) {
          return
        }
      } else if (!(checkbox1.value && checkbox2.value)) {
        if (!form.value.validate()) {
          return
        }
      }
      loading.value = true

      store.dispatch('app-order/fetchMakeOrderStep3').then(async response => {
        if (response.error === 0) {
          if (tab.value === 0 || tab.value === 1) {
            dialog.value = true
            iframe = document.createElement('iframe')
            iframe.setAttribute('id', 'iframe')

            iframe.width = '500'
            iframe.height = '600'
            iframe.srcdoc = response.posParams.html

            document.getElementById('credit').appendChild(iframe)
            const { refNo } = response.posParams
            const method = 'paymentCheck'
            // eslint-disable-next-line no-constant-condition
            // while (true) {
            //   store.dispatch('postMethod', { method, refNo }).then(result => {
            //     if (result.error === 0) {
            //       orderStatus.value = 0
            //       whileStop.value = true
            //     } else if (result.error === 1) {
            //       whileStop.value = true
            //       paymentErrorMsg.value = result.msg
            //       orderStatus.value = 1
            //     }
            //   })
            //   if (whileStop.value) {
            //     if (orderStatus.value === 0) {
            //       store.commit('app/TOGGLE_BASKET_DRAWER', false)
            //       router.push({ name: 'order-completed' })
            //     } else if (orderStatus.value === 1) {
            //       paymentError.value = true
            //       dialog.value = false
            //     }

            //     break
            //   }
            //   await sleep(500000000000000)
            // }
            // iframe.remove()
            // whileStop.value = false

            orderStatus.value = null
          } else if (tab.value === 2 || tab.value === 3) {
            store.commit('app/TOGGLE_BASKET_DRAWER', false)
            router.push({ name: 'order-completed' })
          }
        } else {
          paymentErrorMsg.value = response.msg
          paymentError.value = true
          Vue.swal({
            title: 'Hata',
            html: response.msg,
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
    }

    const paymentCancel = () => {
      orderStatus.value = null
      paymentErrorMsg.value = null
      paymentError.value = false
      loading.value = false
      iframe.remove()
      whileStop.value = true
      orderStatus.value = null
      dialog.value = false
    }

    const desserts = ref([])
    const fetchBalance = () => {
      const method = 'getCustomerBalanceDetailed'
      const customer = store.state.plasiyerCustomer

      store
        .dispatch('postMethod', { method, customer })
        .then(response => {
          if (response.error === 0) {
            userData.value = response.balance[0]
            toplamOdenecekTutar.value =
              -1 * (Number(response.balanceDetail.head[6].TBLVAL) - Number(response.balanceDetail.head[15].TBLVAL))
            desserts.value = [
              {
                name: 'Bakiye',
                tutar: response.balanceDetail.head[0].TBLVAL,
              },
              {
                name: 'Faturalanmamış Sipariş',
                tutar: response.balanceDetail.head[1].TBLVAL,
              },
              {
                name: 'Kendi Çeki/Senedi',
                tutar: response.balanceDetail.head[3].TBLVAL,
              },
              {
                name: 'Müşteri Çeki/Senedi',
                tutar: response.balanceDetail.head[10].TBLVAL,
              },
              
              {
                name: 'Toplam Risk',
                tutar: response.balanceDetail.head[15].TBLVAL,
              },

              {
                name: 'Risk Limiti',
                tutar: response.balanceDetail.head[6].TBLVAL,
              },
              {
                name: 'Kalan Açık Tutar',
                tutar: Number(response.balanceDetail.head[6].TBLVAL) - Number(response.balanceDetail.head[15].TBLVAL),
              },
            ]

            pageLoading.value = false
          }
        })
        .catch(error => {
          if (error.response?.status === 404) {
            userData.value = {}
          }
        })
    }

    onMounted(() => {
      if (userData.role === 'PLSYR') {
        fetchBalance()
      }
    })

    const buttonDisabled = ref(false)

    const deliveryItems=ref([])
//Teslimat Tipi
    const fetchDeliveryListItems =()=>
    {      
      const param = {
        method: 'getCargoList'       
      }
      store
        .dispatch('postMethod', param)
        .then(response => {     
          deliveryItems.value = response.detail     
       /*    const data = response.detail
          deliveryItems.value = data.map(key => ({ text: key.kargo_adi, value: key.kid })) */
        })
        .catch(error => {
          console.log(error)
        })
    }    

    return {
      isAlani,
      statu,
      isAlaniItems,
      statuItems,
      hasMalFazlasi,
      hasPromotion,
      krediKartTemizle,
      buttonDisabled,
      buttonText,
      birinciKart,
      ikinciKart,
      paymentCancel,
      paymentErrorMsg,
      paymentError,
      desserts,
      userData,
      mesafeliStatus,
      onBilgilendirmeStatus,
      form,
      sepetLoading,
      dialog,
      statusFind,
      basketStep2,
      backBasket,
      cargoPrice,
      formValidate,
      basketStepCompleted,
      buyerNote,
      basketStep3,
      counts,
      isRamazanEvent,
      bankItem,
      tab,
      perfectScrollbarOptions,
      shoppingCartItems,
      addressItems,
      errorShow,
      deleteBasketItem,
      musteri,
      number,
      mesafeliSatisSozlesmesi,
      onBilgilendirmeSozlesmesi,
      fabrika,
      tarih,
      checkbox1,
      checkbox2,
      basketStep,
      closeBasket,
      refreshBasket,
      addressSelect,
      toplamOdenecekTutar,
      toplamOdenecekTutarHavale,
      taksitlendirme,
      taksitSecim,
      deliveryDate,
      menuDeliveryDate,
      minDate,
      maxDate,
      dateChange,
      loading,
      bankalar,
      valid,
      trashBasket,
      cardNumberValidate,
      cardNameValidate,
      cardMountValidate,
      cardYearValidate,
      cardCvvValidate,
      kkAdSoyad,
      kkAy,
      kkYil,
      kkCvv,
      kkNo,
      kredikartino,
      kredikartiadsoyad,
      kredikartiay,
      kredikartiyil,
      kredikarticvv,
      kredikartiinstallment,
      selectedInstallment,
      bankaKodu,
      cardFamily,
      cardBankName,
      taksitTutar,
      taksitSayisi,
      snackbar,
      scrollbar,

      tabKKDisabled,
      tabHavaleDisabled,
      tabCHDisabled,
      tabCHnone,
      isMulti,
      payCondition,
      deliveryItems,
      deliveryList,
      icons: {
        mdiText,
        mdiChevronDown,
        mdiBriefcase,
        mdiChevronUp,
        mdiStateMachine,
        mdiClose,
        mdiTruckFast,
        mdiTrashCan,
        mdiContactlessPayment,
        mdiRefresh,
        mdiBasket,
        mdiCalendar,
        mdiCreditCard,
        mdiCreditCardPlus,
        mdiBank,
        mdiMapMarker,
        mdiBasketOff,
        mdiCircle,
        mdiTruckDelivery
      },
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Ad',
          align: 'right',
          sortable: false,
          value: 'name',
        },

        {
          text: 'Değer',
          sortable: false,
          align: 'right',
          value: 'tutar',
        },
      ],
      formData: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      },
    }
  },
  computed: {
    satirToplamFiyat() {
      return this.shoppingCartItems?.toplamlar?.satirToplamFiyat
    },
    satirToplamKDV() {
      return this.shoppingCartItems?.toplamlar?.satirToplamKDV
    },
    toplamBrutFiyat() {
      return this.shoppingCartItems?.toplamlar?.toplamBrutFiyat
    },
    toplamFiyat() {
      return this.shoppingCartItems?.toplamlar?.toplamFiyat
    },
    toplamIndirimTutari() {
      return this.shoppingCartItems?.toplamlar?.toplamIndirimTutari
    },
    toplamIndirimOrani() {
      return this.shoppingCartItems?.toplamlar?.toplamIndirimOrani
    },    
    urunSayisi() {
      return this.shoppingCartItems?.toplamlar?.urunSayisi
    },
    urunSayisiKonya() {
      let result = []
      result = this.shoppingCartItems.detail.filter(item => {
        if (item.selected_plant === '02') {
          return item
        }
      })

      return result.length
    },
    urunSayisiTekirdag() {
      let result = []
      result = this.shoppingCartItems.detail.filter(item => {
        if (item.selected_plant === '01') {
          return item
        }
      })

      return result.length
    },

    toplamPaketSayisi() {
      return this.shoppingCartItems?.toplamlar?.toplamPaketSayisi
    },

    paketSayisiKonya() {
      let result = []
      result = this.shoppingCartItems.detail.filter(item => {
        if (item.selected_plant === '02') {
          return item
        }
      })

      return result.reduce((sum, item) => sum + item.paketSayisi, 0)
    },

    paketSayisiTekirdag() {
      let result = []
      result = this.shoppingCartItems.detail.filter(item => {
        if (item.selected_plant === '01') {
          return item
        }
      })

      return result.reduce((sum, item) => sum + item.paketSayisi, 0)
    },

    address_title() {
      let result = null
      if (this.addressItems.length > 0) {
        result = this.addressItems[this.addressSelect]?.address_title
      }

      return result
    },
  },
  watch: {
    krediKartTemizle(val) {
      this.formData = {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      }
    },
    taksitSecim(val) {
      this.toplamOdenecekTutar = this.taksitlendirme.installment[val].inst_tot_amount

      this.taksitTutar = this.taksitlendirme.installment[val].inst_amount
      this.taksitSayisi = this.taksitlendirme.installment[val].installment_text.charAt(0)
    },
    tab(val) {
      // if (val === 0) {
      //   this.toplamOdenecekTutar = this.taksitlendirme?.installment[this.taksitSecim]?.inst_tot_amount
      // } else {
      //   this.toplamOdenecekTutar = 0
      // }
    },
  },
  methods: {
    customItemText(item) {
      // Hem 'key' hem de 'value' değerlerini kullanarak özel metni oluştur
      return `${item.key} - ${item.value}`
    },
    customItemText1(item) {
      // Hem 'key' hem de 'value' değerlerini kullanarak özel metni oluştur
      return `${item.STATUSDETAIL} - ${item.STEXT}`
    },
    updateCardNumber(val) {
      if (val.length > 18) {
        const payload = {
          method: 'getInstallmentsByBin',
          kk_no: val.replaceAll(' ', ''), // 4506347048543223
          price: this.toplamOdenecekTutar,
        }
        this.$store.dispatch('app-order/fetchBinList', payload).then(response => {
          this.taksitlendirme = response

          this.cardBankName = response.card_bank_name
          this.cardFamily = response.card_family
          this.bankaKodu = response.card_bank_name
          this.kredikartiinstallment = response.installment
        })
        this.cardNumberValidate = true
        this.kkNo = val
        this.kredikartino = val
      } else {
        this.taksitlendirme = {}
        this.cardNumberValidate = false
      }
    },
    updateCardName(val) {
      if (val) {
        this.cardNameValidate = true
        this.kkAdSoyad = val
        this.kredikartiadsoyad = val
      } else {
        this.cardNameValidate = false
      }
    },
    updateCardMonth(val) {
      if (val) {
        this.cardMountValidate = true
        this.kkAy = val
        this.kredikartiay = val
      } else {
        this.cardMountValidate = false
      }
    },
    updateCardYear(val) {
      if (val) {
        this.cardYearValidate = true
        this.kkYil = val
        this.kredikartiyil = val
      } else {
        this.cardYearValidate = false
      }
    },
    updateCardCvv(val) {
      if (val.length >= 3) {
        this.cardCvvValidate = true
        this.kkCvv = val
        this.kredikarticvv = val
      } else {
        this.cardCvvValidate = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/style.scss';
// Set Perfect Scrollbar Container Height
.vertical-shopping-cart-container {
  height: 100%;
  .ps-nav-menu-items {
    height: calc(100% - 35px) !important;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      height: calc(100%) !important;
    }
  }
}
.v-stepper__step__step {
  display: none !important;
}
.v-application--is-ltr .theme--dark.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-left: 0 !important;
}
.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: -8px -36px -16px 0px !important;
}
</style>
